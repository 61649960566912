import * as React from 'react'
import { ToastContainer } from 'react-toastify'
import { CloseIcon } from 'src/assets/icons/customIcons/Close'
import { CourseCompletedIcon } from 'src/assets/icons/customIcons/course-icons/CourseCompleted'
import { CourseFailedIcon } from 'src/assets/icons/customIcons/course-icons/CourseFailed'
import InfoIcon from 'src/assets/icons/customIcons/page-icons/info'
import { useTheme } from 'src/state/providers/Theme'

export default function Toasts(): React.ReactElement {
  const theme = useTheme()

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop
      closeOnClick
      pauseOnFocusLoss
      pauseOnHover
      theme={theme}
      icon={({ type }) =>
        type === 'success' ? (
          <span className="scale-125">
            <CourseCompletedIcon dataTestid="toastSuccessIcon" />
          </span>
        ) : type === 'error' ? (
          <span className="scale-125">
            <CourseFailedIcon dataTestid="toastErrorIcon" />
          </span>
        ) : (
          <InfoIcon dataTestid="toastInfoIcon" />
        )
      }
      closeButton={
        <div className="flex flex-col justify-center">
          <CloseIcon color={theme === 'dark' ? 'white' : 'black'} />
        </div>
      }
    />
  )
}
