import '@formatjs/intl-datetimeformat/polyfill-force'

import '@formatjs/intl-datetimeformat/locale-data/en'
import '@formatjs/intl-datetimeformat/locale-data/ka'

import './helpers/sentry'

import './helpers/firebase'

import Index from './views/Index'

import { createRoot } from 'react-dom/client'

const container = document.body.appendChild(document.createElement('main'))
const root = createRoot(container)

root.render(<Index />)
