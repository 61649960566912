import * as React from 'react'
import { LocaleStateProvider } from 'src/hooks/locale/locale'
import { MaintenanceModeProvider } from 'src/hooks/maintenanceMode'
import { MetaDataProvider } from 'src/hooks/metaData'
import { ThemeManager } from 'src/state/providers/Theme'
import Routing from './Routing'
import EnvironmentDisplay from './components/EnvironmentDisplay'
import { SentryErrorBoundary } from './components/Error'

export default function Index(): React.ReactElement | null {
  return (
    <SentryErrorBoundary>
      <ThemeManager />
      <MaintenanceModeProvider>
        <MetaDataProvider>
          <LocaleStateProvider>
            <EnvironmentDisplay />
            <Routing />
          </LocaleStateProvider>
        </MetaDataProvider>
      </MaintenanceModeProvider>
    </SentryErrorBoundary>
  )
}
