import { init, reactRouterV6BrowserTracingIntegration, replayIntegration } from '@sentry/react'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import env from './env'

if (env.APP_ENV !== 'LOCAL') {
  init({
    dsn: env.SENTRY_DSN,
    integrations: [
      replayIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
  })
}
