import * as d from 'decoders'
import React, { useState } from 'react'
import { useClickAway, useMount } from 'react-use'
import { storageValue, useStorageValue } from 'src/helpers/storage'
import { useTranslatable } from 'src/hooks/locale/utils'

type DenyDate = number | null

const modalClosedAtStorage = storageValue<DenyDate>(
  'GetApplicationModal:modalClosedAt',
  (value) => JSON.stringify(value),
  (serializedValue) => {
    return d.number.verify(JSON.parse(serializedValue))
  }
)

const isIOS = /iP(hone|od|ad)/.test(navigator.userAgent)
const isAndroid = navigator.userAgent.includes('Android')

function handleClick(): void {
  if (isIOS) {
    window.open('https://apps.apple.com/us/app/argus-lms/id1559920018', '_blank')
  } else {
    window.open('https://play.google.com/store/apps/details?id=ge.edu.iliauni.apps.argus', '_blank')
  }

  modalClosedAtStorage.setValue(new Date().getTime())
}
interface Props {
  readonly setPopupOrder: React.Dispatch<React.SetStateAction<number>>
  readonly popupOrder: number
}
export default function GetApplicationModal({ popupOrder }: Props): React.ReactElement | null {
  const t = useTranslatable()
  const ref = React.useRef(null)
  const modalClosedAt = useStorageValue(modalClosedAtStorage)
  const [modalClosed, setModalClosed] = useState<boolean>(true)

  useClickAway(ref, () => {
    setModalClosed(true)
    modalClosedAtStorage.setValue(new Date().getTime())
  })

  useMount(() => {
    if (modalClosedAt != null) {
      const currentDate = new Date().getTime()
      const DiffInDays = (currentDate - modalClosedAt) / (1000 * 3600 * 24)
      if (DiffInDays > 7) {
        setTimeout(() => setModalClosed(false), 1000)
      }
    } else {
      setTimeout(() => setModalClosed(false), 1000)
    }
  })

  if ((!isIOS && !isAndroid) || modalClosed || popupOrder !== 2) return null

  return (
    <div className="fixed z-[100] flex size-full items-end bg-[#00000080]">
      <div className="flex h-1/2 w-full flex-col items-center overflow-y-scroll rounded-t bg-white py-2" ref={ref}>
        <h3 className="my-8 text-center">{t('mobile_application')}</h3>
        <div className="mx-auto flex w-3/4 flex-col justify-center">
          <button className="mb-4 h-[60px] rounded-full bg-primaryRed p-1 text-center text-white" onClick={handleClick}>
            {t('uncategorized:get_app')}
          </button>

          <button
            className="h-[60px] rounded-full border-2 p-1 text-center"
            onClick={() => {
              modalClosedAtStorage.setValue(new Date().getTime())
              setModalClosed(true)
            }}
          >
            {t('uncategorized:continue_web')}
          </button>
        </div>
      </div>
    </div>
  )
}
