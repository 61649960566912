import React from 'react'
import { createStateContext, useInterval } from 'react-use'
import { getMetaData, type MetaData } from 'src/api'
import env from 'src/helpers/env'
import { useApi } from 'src/helpers/hooks'

export type State = boolean

const [useState, StateProvider] = createStateContext<MetaData | null>(null)

export const MetaDataProvider = StateProvider
export const useMetaDataState = useState

export function MetaDataManager(): null {
  const [, setState] = useState()

  const { mutate } = useApi({
    endpoint: getMetaData,
    params: React.useMemo(
      () => ({
        query: {
          get _() {
            return Date.now().toString()
          },
        },
      }),
      []
    ),
    suspense: false,
    onSuccess(data) {
      setState(data)
    },
  })

  useInterval(() => {
    void mutate()
  }, env.REQUIRE_REFRESH_INTERVAL)

  return null
}
