import * as d from 'decoders'
import { useEffect } from 'react'
import { CloseIcon } from 'src/assets/icons/customIcons/Close'
import { storageValue, useStorageValue } from 'src/helpers/storage'
import { useTranslatable } from 'src/hooks/locale/utils'
import cookies from 'src/imgs/cookies.svg'
import { useTheme } from 'src/state/providers/Theme'

interface Props {
  readonly setPopupOrder: React.Dispatch<React.SetStateAction<number>>
}

const shownBannerStorage = storageValue<boolean>(
  'CookieBanner:shownBanner',
  (value) => JSON.stringify(value),
  (serializedValue: string) => {
    return d.boolean.verify(JSON.parse(serializedValue))
  }
)

function CookieBanner({ setPopupOrder }: Props): JSX.Element | null {
  const t = useTranslatable()
  const theme = useTheme()
  const shownBanner = useStorageValue(shownBannerStorage) ?? true

  useEffect(() => {
    if (!shownBanner) {
      setPopupOrder((current) => current + 1)
    }
  }, [setPopupOrder, shownBanner])

  function close(): void {
    setPopupOrder((current) => current + 1)
    shownBannerStorage.setValue(false)
  }

  if (!shownBanner) {
    return null
  }

  return (
    <div
      className="fixed bottom-20 right-1/2 z-[100] w-11/12 translate-x-1/2 overflow-hidden rounded-xl text-left shadow-xl transition-all lg:w-2/3"
      data-testid="CookieBanner"
    >
      <div className="absolute right-2.5 top-2 cursor-pointer" onClick={close} data-testid="CookieBanner/close">
        <CloseIcon color={theme === 'dark' ? 'white' : 'black'} />
      </div>
      <div className="bg-cookieBg px-5 py-6 sm:p-6 sm:pb-4">
        <div className="flex sm:flex sm:items-start">
          <div className="mr-5 hidden h-1/4 w-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:hidden lg:flex lg:w-32">
            <img src={cookies} className="bg-cookieBg" />
          </div>
          <div className="sm:ml-4 sm:mt-0 sm:text-left">
            <div className="flex items-center">
              <img src={cookies} className="mr-5 w-12 bg-cookieBg lg:hidden" />
              <h3 className="text-2xl font-semibold leading-6 text-primaryTextColor">{t('system:cookies_policy')}</h3>
            </div>

            <div className="mt-2">
              <p className="mb-2.5 text-lg text-primaryTextColor">{t('system:cookies_policy_description')}</p>
              <span className="text-base text-black dark:text-primaryGray">
                {t('system:cookies_policy_more_information')}
                <a
                  target="_blank"
                  className="text-primaryBlueLink"
                  href="https://iliauni.edu.ge/ge/cookie-files"
                  rel="noreferrer"
                >
                  {' ' + t('system:cookies_policy')}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookieBanner
