import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'
import { useConsoleWarning } from 'src/helpers/hooks'
import { AuthManager } from 'src/hooks/auth/auth'
import { LocaleManager } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { MaintenanceModeManager, useMaintenanceModeState } from 'src/hooks/maintenanceMode'
import { MetaDataManager } from 'src/hooks/metaData'
import { SentryErrorBoundary } from 'src/views/components/Error'
import { SWRConfig } from 'swr'
import GeneralPopups from './GeneralPopups'
import Toasts from './Toasts'

export default function MainRouteLayout(): JSX.Element {
  const [, setMaintenanceMode] = useMaintenanceModeState()

  return (
    <HelmetProvider>
      <SWRConfig
        value={{
          suspense: true,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          shouldRetryOnError: false,
          refreshWhenOffline: false,
          refreshWhenHidden: false,
          refreshInterval: 0,
          onError(err) {
            try {
              if (err.type === 'MaintenanceMode') {
                setMaintenanceMode(true)
              }
            } catch (err) {
              console.error(err)
            }
          },
        }}
      >
        <SentryErrorBoundary>
          <Outlet />
        </SentryErrorBoundary>

        <MaintenanceModeManager />
        <AuthManager />
        <LocaleManager />
        <MetaDataManager />

        <DefaultHelmet />
        <Toasts />
        <GeneralPopups />
      </SWRConfig>
    </HelmetProvider>
  )
}

function DefaultHelmet(): JSX.Element {
  useConsoleWarning()
  const t = useTranslatable()

  return <Helmet titleTemplate={t('system:argus_s')} defaultTitle={t('system:argus_loading')} />
}
