import { useState } from 'react'
import CookieBanner from '../components/CookieBanner'
import GetApplicationModal from '../components/GetApplicationModal'
import Refresh from './Refresh'

export default function GeneralPopups(): JSX.Element | null {
  const [popupOrder, setPopupOrder] = useState(0)

  return (
    <>
      <Refresh setPopupOrder={setPopupOrder} popupOrder={popupOrder} />
      {popupOrder === 1 && <CookieBanner setPopupOrder={setPopupOrder} />}
      <GetApplicationModal setPopupOrder={setPopupOrder} popupOrder={popupOrder} />
    </>
  )
}
