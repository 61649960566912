export function Update(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16.001 16.001">
      <g id="update_icon" transform="translate(-119 -717.999)">
        <path
          id="Subtraction_238"
          data-name="Subtraction 238"
          d="M14400-3640a8.007,8.007,0,0,1-8-8h1a6.953,6.953,0,0,0,2.053,4.945A6.952,6.952,0,0,0,14400-3641a7.046,7.046,0,0,0,6.71-5h1.04A8,8,0,0,1,14400-3640Zm8-8h-1a6.958,6.958,0,0,0-2.053-4.948A6.954,6.954,0,0,0,14400-3655a7.046,7.046,0,0,0-6.709,5h-1.037a7.992,7.992,0,0,1,7.746-6,8.011,8.011,0,0,1,8,8Z"
          transform="translate(-14273 4374)"
          fill="gray"
        />
        <rect
          id="Rectangle_4320"
          data-name="Rectangle 4320"
          width="6"
          height="1"
          transform="translate(129 728)"
          fill="gray"
        />
        <rect
          id="Rectangle_4322"
          data-name="Rectangle 4322"
          width="6"
          height="1"
          transform="translate(125 724) rotate(180)"
          fill="gray"
        />
        <rect
          id="Rectangle_4321"
          data-name="Rectangle 4321"
          width="1"
          height="6"
          transform="translate(134 728)"
          fill="gray"
        />
        <rect
          id="Rectangle_4323"
          data-name="Rectangle 4323"
          width="1"
          height="6"
          transform="translate(120 724) rotate(180)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
