import env from 'src/helpers/env'
import { useTranslatable } from 'src/hooks/locale/utils'

function EnvironmentDisplay(): JSX.Element | null {
  const t = useTranslatable()
  const environmentName = env.APP_ENV

  if (environmentName === 'PRODUCTION') return null

  return (
    <div className="sticky right-0 z-10 flex h-[35px] w-full items-center justify-center bg-[#454A64] text-white shadow-lg print:hidden">
      {t('environment_display')}: {environmentName}
    </div>
  )
}

export default EnvironmentDisplay
