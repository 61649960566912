import * as React from 'react'
import { useUnmount } from 'react-use'
import { Close2Icon } from 'src/assets/icons/customIcons/Close2'
import { Update } from 'src/assets/icons/customIcons/Update'
import env from 'src/helpers/env'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useMetaDataState } from 'src/hooks/metaData'

interface Props {
  readonly setPopupOrder: React.Dispatch<React.SetStateAction<number>>
  readonly popupOrder: number
}

export default function Refresh({ setPopupOrder }: Props): React.ReactElement | null {
  const [show, setShow] = React.useState(false)
  const [denyCount, setDenyCount] = React.useState(0)
  const t = useTranslatable()
  const [metaData] = useMetaDataState()
  const ref = React.useRef<ReturnType<typeof setTimeout>>()

  React.useEffect(() => {
    if (metaData == null) return

    if (metaData.revision !== env.REVISION) {
      setPopupOrder(0)
      setShow(true)
    } else {
      setPopupOrder((current) => Math.max(current, 1))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData])

  useUnmount(() => {
    if (ref.current != null) {
      clearTimeout(ref.current)
    }
  })

  function handleClose(): void {
    ref.current = setTimeout(() => {
      setPopupOrder(0)
      setShow(true)
    }, 600000)
    setShow(false)
    setDenyCount((current) => current + 1)
    setPopupOrder((current) => Math.max(current, 1))
  }

  if (!show) {
    return null
  }

  return (
    <>
      <div className="fixed flex size-full items-center justify-center bg-black/50 p-2" style={{ zIndex: 9999 }}>
        <div className="left-border-blue flex max-w-[365px] rounded-[12px] bg-card px-[16px] py-[18px]">
          <div className="flex items-start">
            <span className="mt-[2px] flex w-[16px]">
              <Update />
            </span>
            <div className="px-[12px]">
              <h4 className="text-title font-bold">{t('common:update')}</h4>
              <p className="mb-[16px] text-title">{t('system:a_new_version_of_the_application_is_now_available')}</p>
              <button
                className="w-full cursor-pointer rounded-[4px] bg-primaryBlue py-[8px] text-center text-white hover:opacity-80"
                onClick={() => document.location.reload()}
              >
                {t('system:refresh_page')}
              </button>
            </div>
          </div>
          {denyCount <= 3 && (
            <button type="button" onClick={handleClose} className="flex">
              <Close2Icon />
            </button>
          )}
        </div>
      </div>
      <div />
    </>
  )
}
