import { createStateContext, useInterval } from 'react-use'
import { getSemestersActive } from 'src/api'
import { useLocale } from './locale/locale'

export type State = boolean

const [useState, StateProvider] = createStateContext<State>(false)

export const MaintenanceModeProvider = StateProvider
export const useMaintenanceModeState = useState

export function MaintenanceModeManager(): null {
  const locale = useLocale()
  const [state] = useState()

  useInterval(
    () => {
      void getSemestersActive({
        headers: {
          'Accept-Language': locale,
        },
      }).then(() => {
        location.reload()
      })
    },
    state ? 1000 * 60 : null
  )

  return null
}
